$(document).foundation();



var main = $('#menubar ul');

$('.home .scroll').on( 'click',function(event) {
    event.preventDefault();

    var full_url = this.href,
        parts = full_url.split('#'),
        trgt = parts[1],
        target_offset = $('#'+trgt).offset(),
        target_top = target_offset.top;

    $('html, body').animate({scrollTop:target_top - 38}, Math.pow(Math.abs(window.scrollY - $(this.hash).offset().top),2/3)*5);
    if($('.title-bar:hidden').length == 0) {
        $('#titlemenu').hide();
    }

});

$(window).on( 'scroll', function(event) {
    if ( $('body').hasClass('home') ) {
        if($("#presentation").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#present").parent().addClass('active');
            $("#descr1").parent().removeClass('active');
            $("#descr2").parent().removeClass('active');
            $("#descr3").parent().removeClass('active');
            $("#descrbarrique").parent().removeClass('active');
            $("#descr4").parent().removeClass('active');
            $("#prod1").parent().removeClass('active');
            $("#prod2").parent().removeClass('active');
            $("#prod3").parent().removeClass('active');
            $("#prod4").parent().removeClass('active');
            $("#contact").parent().removeClass('active');
        }

        if($("#description1").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#descr1").parent().addClass('active');
            $("#present").parent().removeClass('active');
            $("#descr2").parent().removeClass('active');
            $("#descr3").parent().removeClass('active');
            $("#descrbarrique").parent().removeClass('active');
            $("#descr4").parent().removeClass('active');
            $("#prod1").parent().removeClass('active');
            $("#prod2").parent().removeClass('active');
            $("#prod3").parent().removeClass('active');
            $("#prod4").parent().removeClass('active');
            $("#contact").parent().removeClass('active');
        }

        if($("#description2").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#descr2").parent().addClass('active');
            $("#present").parent().removeClass('active');
            $("#descr1").parent().removeClass('active');
            $("#descr3").parent().removeClass('active');
            $("#descrbarrique").parent().removeClass('active');
            $("#descr4").parent().removeClass('active');
            $("#prod1").parent().removeClass('active');
            $("#prod2").parent().removeClass('active');
            $("#prod3").parent().removeClass('active');
            $("#prod4").parent().removeClass('active');
            $("#contact").parent().removeClass('active');
        }

        if($("#description3").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#descr3").parent().addClass('active');
            $("#present").parent().removeClass('active');
            $("#descr1").parent().removeClass('active');
            $("#descr2").parent().removeClass('active');
            $("#descrbarrique").parent().removeClass('active');
            $("#descr4").parent().removeClass('active');
            $("#prod1").parent().removeClass('active');
            $("#prod2").parent().removeClass('active');
            $("#prod3").parent().removeClass('active');
            $("#prod4").parent().removeClass('active');
            $("#contact").parent().removeClass('active');
        }

        if($("#barrique").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#descrbarrique").parent().addClass('active');
            $("#present").parent().removeClass('active');
            $("#descr1").parent().removeClass('active');
            $("#descr2").parent().removeClass('active');
            $("#descr3").parent().removeClass('active');
            $("#descr4").parent().removeClass('active');
            $("#prod1").parent().removeClass('active');
            $("#prod2").parent().removeClass('active');
            $("#prod3").parent().removeClass('active');
            $("#prod4").parent().removeClass('active');
            $("#contact").parent().removeClass('active');
        }

        if($("#description4").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#descr4").parent().addClass('active');
            $("#present").parent().removeClass('active');
            $("#descr1").parent().removeClass('active');
            $("#descr2").parent().removeClass('active');
            $("#descr3").parent().removeClass('active');
            $("#descrbarrique").parent().removeClass('active');
            $("#prod1").parent().removeClass('active');
            $("#prod2").parent().removeClass('active');
            $("#prod3").parent().removeClass('active');
            $("#prod4").parent().removeClass('active');
            $("#contact").parent().removeClass('active');
        }

        if($("#produit1").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#prod1").parent().addClass('active');
            $("#present").parent().removeClass('active');
            $("#descr1").parent().removeClass('active');
            $("#descr2").parent().removeClass('active');
            $("#descr3").parent().removeClass('active');
            $("#descrbarrique").parent().removeClass('active');
            $("#descr4").parent().removeClass('active');
            $("#prod2").parent().removeClass('active');
            $("#prod3").parent().removeClass('active');
            $("#prod4").parent().removeClass('active');
            $("#contact").parent().removeClass('active');
        }

        if($("#produit2").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#prod2").parent().addClass('active');
            $("#present").parent().removeClass('active');
            $("#descr1").parent().removeClass('active');
            $("#descr2").parent().removeClass('active');
            $("#descr3").parent().removeClass('active');
            $("#descrbarrique").parent().removeClass('active');
            $("#descr4").parent().removeClass('active');
            $("#prod1").parent().removeClass('active');
            $("#prod3").parent().removeClass('active');
            $("#prod4").parent().removeClass('active');
            $("#contact").parent().removeClass('active');
        }

        if($("#produit3").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#prod3").parent().addClass('active');
            $("#present").parent().removeClass('active');
            $("#descr1").parent().removeClass('active');
            $("#descr2").parent().removeClass('active');
            $("#descr3").parent().removeClass('active');
            $("#descrbarrique").parent().removeClass('active');
            $("#descr4").parent().removeClass('active');
            $("#prod1").parent().removeClass('active');
            $("#prod2").parent().removeClass('active');
            $("#prod4").parent().removeClass('active');
            $("#contact").parent().removeClass('active');
        }

        if($("#produit4").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#prod4").parent().addClass('active');
            $("#present").parent().removeClass('active');
            $("#descr1").parent().removeClass('active');
            $("#descr2").parent().removeClass('active');
            $("#descr3").parent().removeClass('active');
            $("#descrbarrique").parent().removeClass('active');
            $("#descr4").parent().removeClass('active');
            $("#prod1").parent().removeClass('active');
            $("#prod2").parent().removeClass('active');
            $("#prod3").parent().removeClass('active');
            $("#contact").parent().removeClass('active');
        }

        if($("#footer").offset().top < $(window).scrollTop() + $(window).outerHeight()) {
            $("#contact").parent().addClass('active');
            $("#present").parent().removeClass('active');
            $("#descr1").parent().removeClass('active');
            $("#descr2").parent().removeClass('active');
            $("#descr3").parent().removeClass('active');
            $("#descrbarrique").parent().removeClass('active');
            $("#descr4").parent().removeClass('active');
            $("#prod1").parent().removeClass('active');
            $("#prod2").parent().removeClass('active');
            $("#prod3").parent().removeClass('active');
            $("#prod4").parent().removeClass('active');
        }
    }
});



// Animations
// Gestion via Greensocks + scrollMagic

// init
TweenMax.set($('#t1'), {opacity:0});
TweenMax.set($('#t2'), {opacity:0});
TweenMax.set($('#t3'), {opacity:0});
TweenMax.set($('#t4'), {opacity:0});
TweenMax.set($('#t5'), {opacity:0});
TweenMax.set($('#t6'), {opacity:0});
TweenMax.set($('#imgchene img'), {opacity:0});
TweenMax.set($('#imgdescr2 img'), {opacity:0});
TweenMax.set($('.chauffeimg img'), {opacity:0});
TweenMax.set($('#logo1 .st0'), {drawSVG:0});
TweenMax.set($('#logo1 .st1'), {opacity:0});
TweenMax.set($('#logo2 .st0'), {drawSVG:0});
TweenMax.set($('#logo2 .st1'), {opacity:0});

TweenMax.set($('#produit1 .maxwidth'), {opacity:0});
TweenMax.set($('#produit2 .maxwidth'), {opacity:0});
TweenMax.set($('#produit3 .maxwidth'), {opacity:0});
TweenMax.set($('#produit4 .maxwidth'), {opacity:0});

// hero
TweenMax.to($('#t6'), 1,{opacity:1});
TweenMax.to($('#t5'), 1,{opacity:1, delay:0.15});
TweenMax.to($('#t4'), 1,{opacity:1, delay:0.3});
TweenMax.to($('#t3'), 1,{opacity:1, delay:0.45});
TweenMax.to($('#t2'), 1,{opacity:1, delay:0.6});
TweenMax.to($('#t1'), 1,{opacity:1, delay:0.75});


TweenMax.to($('#logo1 .st0'), 3, {drawSVG:'100%'});
TweenMax.to($('#logo1 .st1'), 3, {opacity:1, delay:0.5});


// Images affichées au scroll
var controller = new ScrollMagic.Controller();

var sceneImg1 = new ScrollMagic.Scene({ triggerElement: '#imgchene'}).setTween('#imgchene img', 1, {opacity:1}).addTo(controller);
var sceneImg2 = new ScrollMagic.Scene({ triggerElement: '#imgdescr2'}).setTween('#imgdescr2 img', 1, {opacity:1}).addTo(controller);
var sceneImg3 = new ScrollMagic.Scene({ triggerElement: '.chauffeimg'}).setTween('.chauffeimg img', 1, {opacity:1}).addTo(controller);
var sceneImg4 = new ScrollMagic.Scene({ triggerElement: '#produit1'}).setTween('#produit1 .maxwidth', 1, {opacity:1}).addTo(controller);
var sceneImg4 = new ScrollMagic.Scene({ triggerElement: '#produit2'}).setTween('#produit2 .maxwidth', 1, {opacity:1}).addTo(controller);
var sceneImg4 = new ScrollMagic.Scene({ triggerElement: '#produit3'}).setTween('#produit3 .maxwidth', 1, {opacity:1}).addTo(controller);
var sceneImg4 = new ScrollMagic.Scene({ triggerElement: '#produit4'}).setTween('#produit4 .maxwidth', 1, {opacity:1}).addTo(controller);
var sceneImg4 = new ScrollMagic.Scene({ triggerElement: '#presentext'}).setTween($('#logo2 .st0'), 3, {drawSVG:'100%'}).addTo(controller);
var sceneImg4 = new ScrollMagic.Scene({ triggerElement: '#presentext'}).setTween($('#logo2 .st1'), 3, {opacity:1, delay:0.5}).addTo(controller);